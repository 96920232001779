import React, {useEffect, useState} from 'react';
import './App.css';

const BASE_URL = "https://api.ourtodolist.app";

function App() {
    const [todos, setTodos] = useState([]);
    const [newTodoTitle, setNewTodoTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState(null);

    // Function to fetch todos
    const fetchTodos = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${BASE_URL}/todos`);
            if (!response.ok) {
                throw new Error('Failed to fetch todos');
            }
            const data = await response.json();
            setTodos(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTodos();
    }, []);

    // Function to add a new todo
    const addTodo = async (e) => {
        e.preventDefault();
        if (!newTodoTitle.trim()) return;
        setAdding(true);
        setError(null);
        try {
            const response = await fetch(`${BASE_URL}/todos`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({title: newTodoTitle.trim()}),
            });

            if (!response.ok) {
                throw new Error('Failed to add todo');
            }
            setNewTodoTitle('');
            await fetchTodos();
        } catch (err) {
            setError(err.message);
        } finally {
            setAdding(false);
        }
    };

    // Function to mark a todo as completed
    const markTodo = async (title) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${BASE_URL}/todos/${window.btoa(title)}/mark`, {
                method: 'PUT',
            });
            if (!response.ok) {
                throw new Error('Failed to mark todo');
            }
            await fetchTodos();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to delete a todo
    const deleteTodo = async (title) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${BASE_URL}/todos/${window.btoa(title)}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete todo');
            }
            await fetchTodos();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="app-container">
            <h1>Our TODO List</h1>
            <h2>A collective todo list for the whole world</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="form-container">
                <form onSubmit={addTodo} className="todo-form">
                    <input
                        type="text"
                        value={newTodoTitle}
                        onChange={(e) => setNewTodoTitle(e.target.value)}
                        placeholder="Enter new todo"
                        className="input-field"
                        aria-label="New todo title"
                        disabled={adding}
                    />
                    <button type="submit" className="add-button" disabled={adding}>
                        {adding ? 'Adding...' : 'Add Todo'}
                    </button>
                </form>
            </div>
            <div className="todo-lists">
                <div className="todo-list">
                    <h2>Todos</h2>
                    <ul className={loading ? 'loading-state' : ''}>
                        {loading && todos.length === 0 ? (
                            <div>Loading todos...</div>
                        ) : todos.length === 0 ? (
                            <div className="empty-state">
                                <p>No todos yet! Add a new todo to get started.</p>
                            </div>
                        ) : (
                            todos.map((todoStat) => {
                                const incompleteCount = todoStat.total - todoStat.marked;
                                return (
                                    <li key={todoStat.title} className="todo-item">
                                        <span className="todo-title">{todoStat.title}</span>
                                        <span className="todo-count">
                      ({todoStat.marked} / {todoStat.total})
                    </span>
                                        <div className="todo-actions">
                                            {incompleteCount > 0 && (
                                                <button
                                                    className="complete-button"
                                                    onClick={() => markTodo(todoStat.title)}
                                                    disabled={loading}
                                                    aria-label={`Mark ${todoStat.title} as complete`}
                                                >
                                                    Complete
                                                </button>
                                            )}
                                            {todoStat.total > 0 && (
                                                <button
                                                    className="delete-button"
                                                    onClick={() => deleteTodo(todoStat.title)}
                                                    disabled={loading}
                                                    aria-label={`Delete ${todoStat.title}`}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
